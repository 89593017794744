import React from "react";
import { SiteWrapper } from "../components/SiteWrapper";
import Seo from "../components/seo";
import { ACTIVE_PAGE } from "../components/nav/utils";
import {
  MainContainer,
  StyledLink,
  StyledAnchor,
  FAQList,
  ListItemWrapper,
  QuestionHeader,
  CameraHeader,
  CameraItem,
  CameraFeatures,
  CameraUseCases,
  PhotoContainer,
  InfoContainer,
  CameraImage,
  Column,
  CameraList,
  CameraSectionHeader,
  FAQText,
  YoutubeContainer,
  YoutubeIframe,
} from "../page-components/faq/styles";

export default function FaqPage() {
  const faqLink = (
    ytLink: string | string[] | undefined,
    text:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | React.ReactFragment
      | null
      | undefined
  ) => {
    const link = Array.isArray(ytLink) ? ytLink[0] : ytLink;
    return (link ?? "default value")[0] === "/" ? (
      <StyledLink to={link ?? ""}>
        <h2>
          <strong>{text}</strong>
        </h2>
      </StyledLink>
    ) : (
      <StyledAnchor href={link ?? ""} target="_blank" rel="noopener noreferrer">
        <h2>
          <strong>{text}</strong>
        </h2>
      </StyledAnchor>
    );
  };

  const ListItem = ({
    title,
    children,
  }: {
    title: string;
    children: React.ReactNode;
  }) => (
    <ListItemWrapper>
      <QuestionHeader>{title}</QuestionHeader>
      <FAQText>{children}</FAQText>
    </ListItemWrapper>
  );

  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.FAQ}>
      <Seo title="FAQ | Robotic Imaging" />

      <header className="site__header"></header>

      <MainContainer>
        <FAQList>
          <ListItem title="What is Robotic Imaging?">
            <p>
              We are a digital twin creation and maintenance system for the
              built environment. Robotic Imaging connects and analyzes real
              world assets with BIM, 3D scanning, VR imaging and drones across
              the building industry. Our digital strategy is tailored for
              Corporations, Architects, Engineers, General Contractors, Owners
              and Sub-Contractors looking to accelerate projects remotely and at
              scale.
            </p>
          </ListItem>
          <ListItem title="Are there videos that support the Robotic Imaging workflow?">
            <YoutubeContainer>
              <YoutubeIframe
                src="https://www.youtube.com/embed/VlzEpScr0n8?si=7vqTocWSg2Hw3zh0"
                title="How to Import a Point Cloud in Revit"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              />
              <YoutubeIframe
                src="https://www.youtube.com/embed/kqq2qekP_rQ?si=80NDrq8kYXU-eK5q"
                title="Scan-To-BIM: How It Transforms Physical Spaces into Digital Models"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              />
              <YoutubeIframe
                src="https://www.youtube.com/embed/2jbS2xJ1KFk?si=dCyT6tGVaEpKTzZS"
                title="LiDAR Explained"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              />
            </YoutubeContainer>
          </ListItem>
          <br />
          <ListItem title="What Types of Laser Scanners Does Robotic Imaging Deploy?">
<br />
          </ListItem>

          <CameraList>
            <Column>
              {/*  SCANSATION P40 */}
              <CameraItem>
                <PhotoContainer>
                  <CameraImage src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Ffaq%2FLeica%20ScanStation%20P40-05.png?alt=media&token=93ba971d-1c7f-4af6-bc21-34673de9e387" />
                </PhotoContainer>
                <InfoContainer>
                  <CameraHeader>SCANSATION P40</CameraHeader>
                  <CameraUseCases>
                    Use Cases: Large Civil, Industrial Projects
                  </CameraUseCases>
                  <CameraFeatures>Key Features: </CameraFeatures>
                  <CameraFeatures>Speed – 1,000,000 pts/second</CameraFeatures>
                  <CameraFeatures>Range – Min 0.4, Up to 270m </CameraFeatures>
                  <CameraFeatures>3D Point Accuracy </CameraFeatures>
                  <CameraFeatures>Scans 40 - 50m away </CameraFeatures>
                </InfoContainer>
              </CameraItem>

              {/*  SCANSATION P50 */}
              <CameraItem>
                <PhotoContainer>
                  <CameraImage src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Ffaq%2FLeica_ScanStation_P50_2.png_c734003a1O__78914.png?alt=media&token=e00f1514-dc8c-43e3-b027-32c1d796bcce" />
                </PhotoContainer>
                <InfoContainer>
                  <CameraHeader>SCANSATION P50</CameraHeader>
                  <CameraUseCases>
                    Use Cases: Large Civil, Infrastructural Projects
                  </CameraUseCases>
                  <CameraFeatures>Key Features: </CameraFeatures>
                  <CameraFeatures>Speed – 1,000,000 pts/second</CameraFeatures>
                  <CameraFeatures>Range – Min 0.4, Up to 1km </CameraFeatures>
                  <CameraFeatures>3D Point Accuracy </CameraFeatures>
                  <CameraFeatures>Low Range Noise </CameraFeatures>
                </InfoContainer>
              </CameraItem>

              {/* LEICA RTC 360 */}
              <CameraItem>
                <PhotoContainer>
                  <CameraImage src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Ffaq%2FRTC360-05.png?alt=media&token=11bc7715-f3e1-49da-94f1-b28725941dae" />
                </PhotoContainer>
                <InfoContainer>
                  <CameraHeader>LEICA RTC 360</CameraHeader>
                  <CameraUseCases>
                    Use Cases: Industrial, Large Spaces, Land Surveying
                  </CameraUseCases>
                  <CameraFeatures>Key Features: </CameraFeatures>
                  <CameraFeatures>Speed – 2,000,000 pts/second</CameraFeatures>
                  <CameraFeatures>Range – Min 0.5, Up to 130m </CameraFeatures>
                  <CameraFeatures>3D Point Accuracy </CameraFeatures>
                </InfoContainer>
              </CameraItem>

              {/* NAVVIS VLX */}
              <CameraItem>
                <PhotoContainer>
                  <CameraImage src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Ffaq%2FNavvis%20VLX-06.png?alt=media&token=915c33f7-77f0-4a41-8f33-585a79b0f5ed" />
                </PhotoContainer>
                <InfoContainer>
                  <CameraHeader>NAVVIS VLX</CameraHeader>
                  <CameraUseCases>
                    Use Cases: Geo-Registration, Surveying, Higher LOD Projects
                  </CameraUseCases>
                  <CameraFeatures>Key Features: </CameraFeatures>
                  <CameraFeatures>Speed – 300,000 pts/second</CameraFeatures>
                  <CameraFeatures>Range – 100m max </CameraFeatures>
                  <CameraFeatures>903m Wavelength </CameraFeatures>
                </InfoContainer>
              </CameraItem>
            </Column>
            <Column>
              {/* LEICA BLK 360 */}
              <CameraItem>
                <PhotoContainer>
                  <CameraImage src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Ffaq%2FBLK-05.png?alt=media&token=ae3d0580-ce0d-46df-a79f-a5d29c6abdb1" />
                </PhotoContainer>
                <InfoContainer>
                  <CameraHeader>LEICA BLK 360</CameraHeader>
                  <CameraUseCases>
                    Use Cases: Residential, Offices, Drop Ceilings, Roofing,
                    Small Spaces
                  </CameraUseCases>
                  <CameraFeatures>Key Features: </CameraFeatures>
                  <CameraFeatures>Speed – 360,000 pts/second</CameraFeatures>
                  <CameraFeatures>Range – Min 0.6, Up to 60m </CameraFeatures>
                  <CameraFeatures>3D Point Accuracy </CameraFeatures>
                </InfoContainer>
              </CameraItem>

              {/* MATTERPORT PRO3 */}
              <CameraItem>
                <PhotoContainer>
                  <CameraImage src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Ffaq%2FMatterport%20Pro3-05.png?alt=media&token=66c54462-b782-4914-924c-a48767d73059" />
                </PhotoContainer>
                <InfoContainer>
                  <CameraHeader>MATTERPORT PRO3</CameraHeader>
                  <CameraUseCases>
                    Use Cases: 3D Visualization of Current Conditions
                  </CameraUseCases>
                  <CameraFeatures>Key Features: </CameraFeatures>
                  <CameraFeatures>Speed – 100,000 pts/second</CameraFeatures>
                  <CameraFeatures>Range – 100m </CameraFeatures>
                  <CameraFeatures>Less than 20 seconds/spin </CameraFeatures>
                  <CameraFeatures>Shareable Link </CameraFeatures>
                </InfoContainer>
              </CameraItem>

              {/* LEICA BLK 2 */}
              <CameraItem>
                <PhotoContainer>
                  <CameraImage src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Ffaq%2FLeica_BLK360_G2.png?alt=media&token=1b93676b-2892-4128-ad82-722688d49b1e" />
                </PhotoContainer>
                <InfoContainer>
                  <CameraHeader>LEICA BLK 2</CameraHeader>
                  <CameraUseCases>
                    Use Cases: Residential, Offices, HDR Imaging, Small Spaces
                  </CameraUseCases>
                  <CameraFeatures>Key Features: </CameraFeatures>
                  <CameraFeatures>Speed – 34,000 pts/second</CameraFeatures>
                  <CameraFeatures>Range – Min 0.6, Up to 45m </CameraFeatures>
                  <CameraFeatures>3D Point Accuracy </CameraFeatures>
                </InfoContainer>
              </CameraItem>

              {/* MATTERPORT PRO2 */}
              <CameraItem>
                <PhotoContainer>
                  <CameraImage src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Ffaq%2FMatterport%20Pro2-05.png?alt=media&token=b7a7a56d-34f8-4d62-babc-bdd9b372198b" />
                </PhotoContainer>
                <InfoContainer>
                  <CameraHeader>MATTERPORT PRO2</CameraHeader>
                  <CameraUseCases>
                    Use Cases: 3D Visualization of Current Conditions
                  </CameraUseCases>
                  <CameraFeatures>Key Features: </CameraFeatures>
                  <CameraFeatures>4k Resolution</CameraFeatures>
                  <CameraFeatures>5m Distance</CameraFeatures>
                  <CameraFeatures>20 seconds/spin</CameraFeatures>
                  <CameraFeatures>Shareable Link</CameraFeatures>
                </InfoContainer>
              </CameraItem>
            </Column>
          </CameraList>
          <ListItem title="How much does Robotic Imaging cost?">
            <p>
              The more accurate to reality, the more time and cost. We collect
              LiDAR and colorized rgb values with details custom to project
              goals.
            </p>
          </ListItem>

          <ListItem title="Where and When is Robotic Imaging available?">
            <p>
              Anywhere, 24/7! Using our free scheduling app, you choose when and
              where you need us.
            </p>
          </ListItem>

          <ListItem title="What if I can’t order Robotic Imaging in my country or region?">
            <p>
              We are able to service every region and most countries. If you
              have a special project like this. Reach out{" "}
              <a href="https://www.roboticimaging.com/contact">here.</a>
            </p>
          </ListItem>

          <ListItem title="Does Robotic Imaging store my Personal Information?">
            <p>
              Our{" "}
              <a href="https://www.roboticimaging.com/privacy-policy">
                Privacy Policy
              </a>{" "}
              contains details about how we collect, use, and share Personal
              Information that we obtain from and about you when you interact
              with us on our website, via our mobile app, via email, and through
              other online and offline interactions. Our Privacy Notice is{" "}
              <a href="https://www.roboticimaging.com/privacy-policy">here</a>.
              Reach out to us at{" "}
              <a href="mailto:info@roboticimaging.com" className="yellow-link">
                info@roboticimaging.com
              </a>{" "}
              if you have questions or concerns.
            </p>
          </ListItem>

          <ListItem title="Where can I write a Robotic Imaging review?">
            <p>
              If you’d like to let us know how we’re doing, write a review on
              your app store on{" "}
              <a href="https://play.google.com/store/apps/details?id=com.roboticimaging&pcampaignid=web_share&pli=1">
                Google Play
              </a>{" "}
              or{" "}
              <a href="https://apps.apple.com/us/app/robotic-imaging/id1546776636">
                Apple App Store
              </a>
              . We love to hear feedback from our customers. Are you a brand
              interested in working with Robotic Imaging? Reach out{" "}
              <a href="https://www.roboticimaging.com/contact">here</a>.
            </p>
          </ListItem>

          <ListItem title="Can I transfer any data directly to my project page?">
            <p>
              Absolutely! You can transfer data directly to your project page by
              entering your project password into{" "}
              <a
                href="https://roboticimaging.ai/deliverables/e6QCykLflAJu0JEGIkEC/upload"
                style={{ color: "#ffb310" }}
              >
                this transfer link
              </a>
              . You can also share your project password with others, so that
              they can upload data to the project page without having to login.
            </p>
          </ListItem>
          <ListItem title="I don’t see an answer to my question. What should I do?">
            <p>
              {" "}
              If you do not see an answer to your question here, please reach
              out: +1 267-969-1153,{" "}
              <a href="mailto:info@roboticimaging.com" className="yellow-link">
                info@roboticimaging.com
              </a>
              , or you can quickly submit info to our team{" "}
              <a href="https://www.roboticimaging.com/contact">here</a>.
            </p>
          </ListItem>
        </FAQList>
      </MainContainer>
    </SiteWrapper>
  );
}
